export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Affaires',
    route: 'Affaires',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Planning',
    route: 'Planning',
    icon: 'CalendarIcon',
  },
  {
    title: 'Annuaire',
    icon: 'BookIcon',
    children:[
      {
        title: 'Clients',
        route: 'Clients',
      },
    ]
  },
  {
    title: 'Entreprise',
    icon: 'UsersIcon',
    children:[
      {
        title: 'Collaborateurs',
        route: 'Collaborateurs',
      },
    ]
  },
  {
    title: 'Paramètres',
    icon: 'SettingsIcon',
    children:[
      {
        title: 'Vues de planning',
        route: 'Vues de planning',
      },
      {
        title: 'Types d\'évènements',
        route: 'TypesEvenements',
      },
      {
        title: 'Métiers',
        route: 'Metiers',
      },
      {
        title: 'Contrats',
        route: 'TypesContrats',
      },

    ]
  }
]
