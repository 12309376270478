<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name">
          {{ userData ? userData.firstName + ' ' + userData.lastName : "" }}
        </p>
        <span class="user-status">{{ userData ? userData.roles.toString() : "" }}</span>
      </div>
      <b-avatar
        size="32"
        :src="userData? userData.avatar : ''"
        class="rounded bg-white text-primary"
      >
        <span class="b-avatar-text">
          <div class="text-primary">{{userData ? userData.firstName.charAt(0) + userData.lastName.charAt(0)  : 'ND'}}</div>
        </span>
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$router.push('account')"
    >
      <span>
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        Mon Compte
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$router.push('/workspaces')"
    >
      <feather-icon size="16" icon="HomeIcon" class="mr-50" />
      <span>Espaces de travail</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <span>
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        Déconnexion
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('login'))?.login?.userInfo,
    }
  },
  methods: {
    logout() { 
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
  },
}
</script>
